<template>
  <div class="container contactUs mt-5" style="text-transform: uppercase">
    <div class="col">
      <div>
        <h5>{{ $t("contactForm.formFollowUs") }}</h5>
      </div>
      <div class="line col mt-2 mb-2"></div>
      <div class="purple"><p v-html="items.phoneNumber"></p></div>
    </div>
    <div class="col mt-3">
      <div>
        <h5>{{ $t("contactForm.formAddress") }}</h5>
      </div>
      <div class="col line mt-2 mb-2"></div>
      <div class="purple">
        <p v-html="lang == 'ka' ? items.addressKA : items.addressEN"></p>
      </div>
    </div>
    <div
      class="col mt-3"
      v-if="items.email.length != 0 && items.emailIcon != null"
    >
      <div v-if="items.emailIcon != null">
        <img
          :src="`${server_url}/${items.emailIcon}`"
          alt=""
          class="image ml-1"
          style="height: 25px; width: 25px"
        />
      </div>
      <div class="col line mt-2 mb-2"></div>
      <div
        class="emailAdress"
        v-for="(item, index) in items.email"
        :key="index"
      >
        <span style="color: #5d5d5d" v-html="item"></span>
      </div>
    </div>
    <div class="col mt-3 mb-5">
      <div>
        <h5>{{ $t("contactForm.formFollow") }}</h5>
      </div>
      <div class="col line mt-2 mb-2"></div>
      <div class="d-flex image">
        <div class="mr-3" v-for="item in items.follow" :key="item._id">
          <a :href="item.url" target="_blank">
            <img :src="`${server_url}/${item.image}`" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
export default {
  data() {
    return {
      server_url: env.server_url,
      lang: "ka",
      items: [],
      emailIcon: null,
      email: null,
    };
  },

  mounted() {
    axios
      .get(`${env.host}/get/informations/contactInformation`)

      .then((result) => {
        this.items = result.data.item;
        this.email = result.data.item.email;
      })
      .catch((err) => console.log(err));
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    }
  },
};
</script>


<style scoped>
.emailAdress {
  word-break: break-word;
  text-transform: none;
}
.contactUs {
  color: #5d5d5d;
  margin: auto;
  text-align: left;
}
.image {
  width: 100%;
  flex-wrap: wrap;
}
.image img {
  width: 25px;
  margin: 5px;
}

.line {
  background-color: #7e5493;
  width: 50px;
  border: 1px solid #7e5493;
}

@media all and (max-width: 768px) {
  .contactUs {
    margin: auto;
  }
}
</style>

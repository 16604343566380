<template>
  <div>
    <Navbar />
    <div class="mainContainer pt-0 pl-0 pr-0 d-flex">
      <div class="container">
        <div class="row pl-0 pr-0">
          <div
            class="col-sm photo"
            :style="{
              backgroundImage: `url('${server_url}/${career.image}')`,
            }"
          >
            <div class="d-flex pl-5 contactTitle">
              <h2 style="text-transform: uppercase" class="mtavruliBOLD purple">
                {{ $t("contactForm.contactUS") }}
              </h2>
            </div>
          </div>
        </div>
        <div class="row pb-5 seperated d-flex justify-content-between">
          <div class="pt-5 formSide col-sm-12 col-md-8 col-12 col-lg-8">
            <Form />
          </div>
          <div
            style="background-color: #f5f2f4"
            class="findContainer col-sm-12 col-md-4 col-12 col-lg-3 mt-5"
          >
            <FindUs />
          </div>
          <div class="line"></div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../../components/Navbar/newNavbar/Navbar.vue";
import Footer from "../../../components/Footer/newFooter/Footer.vue";
import Form from "./Form.vue";
import FindUs from "./FIndUs.vue";
import axios from "axios";
import env from "../../../env.json";

export default {
  name: "contact",
  components: { Navbar, Footer, Form, FindUs },
  data() {
    return {
      career: {},
      server_url: env.server_url,
    };
  },
  mounted() {
    axios
      .get(`${env.host}/get/informations/contact`)
      .then((result) => {
        this.career = result.data.item;
        this.isLoading = false;
        console.log(this.career.image, "careerinfo");
      })
      .catch((err) => console.log(err));
  },
};
</script>

<style scoped>
.mainContainer {
  width: 100%;
  max-width: 1400px;
  margin: auto;
  padding: 0px;
  min-height: calc(100vh - 190px);
  padding: 20px 0px 20px 0px;
}
.container {
  width: 100%;
  max-width: 1400px;
}
.title h4 {
  color: #7e5493;
  font-weight: 600;
  font-family: mtavruli;
}
.photo {
  height: 20rem;
  max-width: 1400px;
  margin: auto;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.formSide {
  padding-left: 0px;
}
.findContainer {
  margin-bottom: 30px;
}
.line {
  height: 1px;
  background-color: black;
  width: 25%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: auto;
}

.contactTitle {
  justify-content: start;

  bottom: 10px;
  position: absolute;
}

@media all and (max-width: 1500px) {
  .seperated {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media all and (max-width: 991px) {
  .line {
    width: 33%;
  }
  .photo {
    height: 18rem;
  }
}

@media all and (max-width: 768px) {
  .formSide {
    padding-right: 0px;
  }

  .line {
    width: 100%;
  }
}

@media all and (max-width: 575px) {
  .photo {
    height: 14rem;
  }
}

@media all and (max-width: 522px) {
  .contactTitle {
    justify-content: center;
    align-items: center;

    margin: auto;
    display: flex;
    bottom: 10px;
  }

  .contactTitle h2 {
    font-size: 28px !important;
    align-items: center;
  }
}

@media all and (max-width: 402px) {
  .contactTitle h2 {
    font-size: 25px !important;
    align-items: center;
  }
}
</style>

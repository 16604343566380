<template>
  <div class="formConatainer">
    <form class="pl-5 pt-5 pr-5" @submit.prevent="submitForm">
      <label class="title">
        <h5>
          {{ $t("contactForm.contactText1") }}<br />
          {{ $t("contactForm.contactText2") }}
        </h5></label
      >

      <div class="row pt-1" style="text-transform: uppercase">
        <div class="col-12 col-sm-6">
          <label>{{ $t("contactForm.formName") }}</label>
          <input
            v-model="firstName"
            type="text"
            class="form-control"
            required="required"
          />
        </div>
        <div class="col-12 col-sm-6 secondFiels">
          <label>{{ $t("contactForm.formSurname") }}</label>
          <input
            v-model="lastName"
            type="text"
            class="form-control"
            required="required"
          />
        </div>
      </div>
      <div class="row pt-4" style="text-transform: uppercase">
        <div class="col-12 col-sm-6">
          <label>{{ $t("contactForm.formPhone") }}</label>
          <input
            v-model="phone"
            type="number"
            class="form-control"
            required="required"
          />
        </div>
        <div class="col-12 col-sm-6 secondFiels">
          <label for="inputEmail4">{{ $t("contactForm.formPost") }}</label>
          <input
            v-model="email"
            type="email"
            class="form-control"
            id="inputEmail4"
            required="required"
          />
        </div>
      </div>

      <div class="form-group pt-4 pb-4" style="text-transform: uppercase">
        <label for="exampleFormControlTextarea1">{{
          $t("contactForm.formMessage")
        }}</label>
        <textarea
          required
          v-model="message"
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="4"
        ></textarea>
      </div>
      <div class="col d-flex justify-content-center">
        <button
          type="submit"
          class="btn btn-primary"
          style="margin-top: auto; text-transform: uppercase"
        >
          {{ $t("front.send") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
import VueSweetalert2 from "vue-sweetalert2";
import Swal from "sweetalert2";

export default {
  components: {
    VueSweetalert2,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
    };
  },
  methods: {
    submitForm() {
      console.log(this.firstName);
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success1",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      axios
        .post(`${env.host}/add/contact`, {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone.toString(),
          message: this.message,
        })
        .then((res) => {
          swalWithBootstrapButtons.fire({
            text: this.$t("front.succes"),
          });
          window.location.reload();
        })
        .catch((err) => {
          this.$swal({
            type: "error",

            text: this.$t("front.error"),
          });
          this.error = err;
          console.log(this.error);
        });
    },
  },
};
</script>

<style >
.btn-success1 {
  background-color: #7e54939f;
  color: white;
}

.btn-success1:hover {
  background-color: #7e5493;
  color: white;
}
::v-deep.btn-success1:focus {
  box-shadow: none !important;
}
.btn.focus,
.btn:focus {
  box-shadow: none;
}
.btn {
  border-radius: 0px;
}
.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 32em;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 0px;
  background: #fff;
  color: #545454;
  font-family: inherit;
  font-size: 1rem;
}
</style>

<style scoped>
.btn {
  border-radius: 0px;
}

.formConatainer {
  background-color: #f5f2f4;
}

.title {
  color: #7e5493;
}

.title h5 {
  line-height: 2rem;
}
.btn-primary {
  border-radius: 0px;
  background-color: #7e54939f;

  padding: 5px 35px 5px 35px;
  margin: auto;
  border: none;
  margin-bottom: 10px;
}
.btn-primary:hover {
  background-color: #7e5493;
  border-color: #7e5493;
}
.form-control {
  border-radius: 0px;
}
::v-deep.form-control:focus {
  border-color: #7e5493;
  box-shadow: none;
}
::v-deep.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem #956ea98e !important;
  background-color: #7e5493;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #7e5493;
  border-color: #7e5493;
}

@media all and (max-width: 768px) {
  .title {
    margin: auto;
    justify-content: center;
    text-align: left;
  }

  @media all and (max-width: 575px) {
    .secondFiels {
      padding-top: 20px;
    }
  }
} ;
</style>
